// @ts-nocheck
/* eslint-disable padding-line-between-statements, import/order, import/newline-after-import, spaced-comment */
/* eslint-disable @typescript-eslint/no-unused-vars, react/jsx-newline, react/jsx-no-bind, no-else-return */
/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps, @typescript-eslint/no-use-before-define, no-lonely-if, no-nested-ternary, import/no-extraneous-dependencies */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-newline */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useContext, useEffect, useState } from 'react';

import {
  Button,
  Checkbox,
  createStyles,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import { routeConstants } from '_constants';
import {
  assignRouteGroups,
  changeRoutesStatus,
  clearRoutesGroups,
  deleteRoute,
  deleteRoutes,
  getGroups,
  getRoutes,
} from 'actions';
import { CardTitle } from 'app/components/shared/card-title';
import { DataTableContainer } from 'app/components/shared/data-table-container';
import { GroupsMenuMultiSelect } from 'app/components/shared/groups-menu-multi-select';
import { LoadingSpinner } from 'app/components/shared/loading-spinner';
import { ActionAlert } from 'app/components/shared/on-action-alert';
import { GroupsCell, StatusCell, TextCell } from 'app/components/shared/table';
import { SystemEmptyState } from 'app/components/system-empty-state';
import { TableRowActions } from 'app/components/table-row-actions';
import {
  actionButtonStyles,
  basicButtonStyles,
  tableRowStyles,
} from 'app/shared/styles';
import { LanguageContext } from 'context/intl.context';
import { getTranslated, trimTrailingSlash, useTypedSelector } from 'helpers';
import {
  canAddNewEntity,
  canEditOperationsSubSystemEntities,
  canEditOperationsSubSystemEntity,
  getEntityGroupsWithReadPermission,
  getSelectedEntityOrganizationIdById,
  haveSameOrganization,
} from 'selectors';
import { Route } from 'types';

import biimg from "./Power-BI-Logo.png";
import { toast } from 'react-toastify';
import { ReactComponent as CheckBoxIcon } from './check_box.svg';
import { FilterRoutesByName } from './filter-routes';
import { ReactComponent as UnselectIcon } from './unselect-icon.svg';
import {
  PaginationComponent,
  usePagination,
} from '../../components/use-pagination';
import axios from 'axios';
const useStyles = makeStyles(() =>
  createStyles({
    table: { minWidth: 1000, overflow: 'scroll', whiteSpace: 'nowrap' },
    addButton: { ...basicButtonStyles },
    row: { ...tableRowStyles },
    multiActionButton: {
      ...actionButtonStyles,
      height: 27,
      padding: '4px 18px',
    },
    dataButton: { marginRight: 10, display: 'none' },
    selectedTableHead: {
      backgroundColor: '#4b506d !important',
      width: '100%',
      padding: 6,
    },
  })
);

interface RouteListItemProps {
  route: Route;
  isSelected: boolean;
  toggleSelection: (id: string) => void;
}

const RouteListItem = ({
  route,
  isSelected,
  toggleSelection,
}: RouteListItemProps) => {
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);
  const { locale } = useContext(LanguageContext);

  const parentOrganization = getTranslated(route?.organisation, locale);

  const hasEditPermissionOnOrganizationRoute = useTypedSelector(state =>
    canEditOperationsSubSystemEntity(state, 'Routes', route.id)
  );

  const routeGroups = useTypedSelector(state =>
    getEntityGroupsWithReadPermission(state, 'Routes', route.id)
  );

  return (
    <TableRow
      onClick={() => {
        history.push(`${baseURL}/${route.id}`);
      }}
      className={classes.row}
      hover
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={isSelected}
          onClick={(event: React.MouseEvent) => {
            event.stopPropagation();
            toggleSelection(route.id);
          }}
          checkedIcon={
            <CheckBoxIcon
              fill="#0fa66d"
              style={{
                backgroundColor: '#fff',
                borderRadius: 3,
              }}
            />
          }
        />
      </TableCell>

      <TextCell label={route.en_name} maxWidth={200} />

      <TextCell
        label={route.ar_name}
        style={{ fontFamily: 'Cairo' }}
        maxWidth={200}
      />

      <TextCell label={route.code} maxWidth={150} />

      <TextCell label={parentOrganization} maxWidth={150} />

      <StatusCell isDisabled={route.disabled} />

      <GroupsCell groups={routeGroups} />

      <TableRowActions
        hasWriteAccess={hasEditPermissionOnOrganizationRoute}
        editPath={`${baseURL}/${route.id}/edit`}
        entityId={route.id}
        entityName={route.en_name}
        entityType="route"
        deleteAction={deleteRoute}
      />
    </TableRow>
  );
};

const RouteList: React.FC = () => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);

  const hasRouteWriteAccess = useTypedSelector(state =>
    canAddNewEntity(state, 'Routes')
  );

  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const { direction } = useContext(LanguageContext);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isSearching, setSearching] = useState(false);
  const [actionCall, setActionCall] = useState(undefined);
  const [isFiltering, setFiltering] = useState(false);

  const [filterParams, setFilterParams] = useState<{ [key: string]: string }>(
    {}
  );

  const [isActionConfirmationModalOpen, setActionConfirmationModalOpen] =
    useState(false);

  const [multiActionTitle, setMultiActionTitle] = useState('');

  // Remove until fix multi select group button double click to open issue
  useEffect(() => {
    dispatch(getGroups(0, 100));
  }, [dispatch]);

  const hasEditPermissionOnSelectedRoutes = useTypedSelector(state =>
    canEditOperationsSubSystemEntities(state, 'Routes', selectedItems)
  );

  const hasSameOrg = useTypedSelector(state =>
    haveSameOrganization(state, 'Routes', selectedItems)
  );

  const selectedEntitiesOrganizationId = useTypedSelector(state =>
    getSelectedEntityOrganizationIdById(state, {
      entityName: 'Routes',
      entityId: selectedItems?.[0],
    })
  );

  const fetchSearchResults = useCallback(
    (page: number, rowSize: number) =>
      getRoutes(page, rowSize, {
        en_name_or_ar_name_or_code_cont: searchTerm,
      }),
    [searchTerm]
  );

  const fetchFilterResults = useCallback(
    (page: number, rowSize: number) => {
      let showDisabledOnly: boolean | undefined;
      if (filterParams?.disabled_in?.length === 1) {
        showDisabledOnly = filterParams?.disabled_in?.includes('disabled');
      }

      return getRoutes(page, rowSize, {
        ...filterParams,
        disabled_in: [showDisabledOnly],
      });
    },
    [filterParams]
  );

  const fetchAction = useCallback(() => {
    if (isSearching) return fetchSearchResults;

    if (isFiltering) return fetchFilterResults;

    return getRoutes;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchFilterResults, isFiltering, isSearching]);

  const { rowsPerPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination({ fetchAction: fetchAction() });

  const resetPageState = () => {
    if (selectedItems?.length) {
      setSelectedItems([]);
    }

    handleChangePage(null, 0);
  };

  const filterRoutes = filters => {
    if (Object.values(filters)?.length) {
      setFiltering(true);
      setSearching(false);
      setSearchTerm('');
    }

    resetPageState();

    setFilterParams(filters);
  };

  const routeListIsLoading = useTypedSelector(
    state => state.routes.isLoading as boolean
  );

  const routeListHasError = useTypedSelector(state => state.routes.hasError);
  const routesTotalCount = useTypedSelector(state => state.routes.totalCount);

  const routeList = useTypedSelector(state => {
    if (!state.routes.isLoading && Object.values(state.routes).length) {
      return Object.values(state.routes.collection);
    }

    return [];
  });

  const handleSearch = () => {
    if (searchTerm) {
      dispatch(
        getRoutes(page, rowsPerPage, {
          en_name_or_ar_name_or_code_cont: searchTerm,
        })
      );

      setSearching(true);
    } else {
      setSearching(false);
    }

    resetPageState();
  };

  const toggleSelection = (id: string) => {
    if (selectedItems.includes(id)) {
      setSelectedItems([...selectedItems.filter(item => item !== id)]);
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const isAllListSelected =
    routeList.length && selectedItems.length === routeList.length;

  const toggleSelectAll = () => {
    if (isAllListSelected) {
      setSelectedItems([]);
    } else {
      const listToBeSelected = routeList.map(route => route.id);
      setSelectedItems(listToBeSelected);
    }
  };

  const onChangePage = (event: unknown, newPage: number) => {
    if (page !== newPage) {
      setSelectedItems([]);
    }

    return handleChangePage(event, newPage);
  };

  const Pagination = () => (
    <PaginationComponent
      {...{
        totalCount: routesTotalCount,
        hasData: !!routeList.length,
        rowsPerPage,
        page,
        handleChangePage: onChangePage,
        handleChangeRowsPerPage,
      }}
    />
  );

  const RenderActionButton = ({ title, onClick }) => (
    <Button
      variant="contained"
      color="default"
      type="button"
      className={classes.multiActionButton}
      onClick={() => {
        setMultiActionTitle(title);
        setActionConfirmationModalOpen(true);
        setActionCall(onClick);
      }}
    >
      {intl.formatMessage({ id: title })}
    </Button>
  );

  const assignRoutesToGroups = onClick => {
    setMultiActionTitle('assign');
    setActionConfirmationModalOpen(true);
    setActionCall(onClick);
  };

  const EnhancedTableToolbar = () => {
    if (selectedItems?.length) {
      return (
        <div className={classes.selectedTableHead}>
          <Checkbox
            checkedIcon={
              isAllListSelected ? (
                <CheckBoxIcon
                  fill="#0fa66d"
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: 3,
                  }}
                />
              ) : (
                <UnselectIcon
                  fill="#0fa66d"
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: 3,
                  }}
                />
              )
            }
            checked={!!selectedItems?.length}
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
              setSelectedItems([]);
            }}
          />

          {hasEditPermissionOnSelectedRoutes && (
            <>
              {hasSameOrg && (
                <GroupsMenuMultiSelect
                  entityName="Routes"
                  onAddGroups={groups =>
                    assignRoutesToGroups(() =>
                      assignRouteGroups({
                        routeIdList: selectedItems,
                        addedGroupIdList: groups,
                        removedGroupIdList: [],
                      })
                    )
                  }
                  selectedOrganizationId={selectedEntitiesOrganizationId}
                />
              )}

              {hasSameOrg && (
                <RenderActionButton
                  title="clearGroups"
                  onClick={() => clearRoutesGroups(selectedItems)}
                />
              )}

              <RenderActionButton
                title="enable"
                onClick={() =>
                  changeRoutesStatus(selectedItems, routeConstants.ENABLED)
                }
              />

              <RenderActionButton
                title="disable"
                onClick={() =>
                  changeRoutesStatus(selectedItems, routeConstants.DISABLED)
                }
              />

              <RenderActionButton
                title="delete"
                onClick={() => deleteRoutes(selectedItems)}
              />
            </>
          )}
        </div>
      );
    }

    return null;
  };
  const openLink = (url) => {
    // Attempt to open a new window with as minimal UI as possible.
    // Note: Modern browsers may not allow hiding the address bar.
    window.open(url, '_blank', 'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=400');
  };

  const [adminOrgCode, setAdminOrgCode] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Loading state
       //auth
       let authData = null
       let userData = null
       let userToken =null
   
 try{
        
        authData =useSelector (state => state.authentication?.user);
         //authData = JSON.parse(localStorage.getItem("authData"));
         userData = authData.userData
         userToken = authData.userToken
 }catch(e){
     toast.error(`Sorry , ${"Auth Failed"} , Code : #85arx`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
      })   
 }
  useEffect(()=>{
//request to get org code using logged admin org id 
//by this code we using to build dynamic links to each org admin
    const getOrgCodeUsingAdminOrgId =  () => {
      setIsLoading(true); // Set loading to true when data is being fetched
    
      //setTimeout(() => {
    axios.get(`${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/fareMedia/getDashboardAdminOrgCodeUsingitsOrgId`,
    { 
      headers:
      {
      'access-token' : userToken['access-token'],
      'uid' :userToken.uid ,
      'client': userToken.client 
      }
      })
  .then((res)=> {
        setAdminOrgCode(res.data.data.org_code);
        console.log("admin org_code : ", res.data.data.org_code);
        setIsLoading(false); // Set loading to false when data is fetched
    })
    .catch((err)=>{
      toast.error(`Sorry , ${err} , Code : #85ar`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
        })           
    
        setIsLoading(false); // Set loading to false when data is fetched
         
      })
   // }, 3000);
  }
   getOrgCodeUsingAdminOrgId();

  
    
      },[])
  return (
    <div dir={direction}>
      <CardTitle title={intl.formatMessage({ id: 'routes' })}>
        {hasRouteWriteAccess && (
          <Button
            component={Link}
            to={`${baseURL}/new`}
            variant="contained"
            className={classes.addButton}
            color="secondary"
          >
            {intl.formatMessage({ id: 'addRoute' })}
          </Button>
        )}
      </CardTitle>

      <Grid container style={{ justifyContent: 'space-between' }}>
        <Grid item>
          <FilterRoutesByName
            {...{
              searchTerm,
              setSearchTerm,
              handleSearch,
              showFilters: !!routeList.length && !isSearching,
              filterRoutes,
              isSearching,
            }}
          />
        </Grid>
        {<>
        <button
      onClick={() => openLink(`https://bi.avl.${adminOrgCode}.mwasla.tech`)}
      style={{
        backgroundColor: 'transparent',
        fontSize:"14px",
        border: 0,
        padding: 0,
        fontFamily: 'Arial, sans-serif',
        textDecoration: 'none',
        margin:"0px 20px"
      }}
    >
     <span   className='bi-btns' style={{

        color: '#4B506D',
        border: '1px solid #E6E6F1',
        borderRadius:"19px",
        cursor: 'pointer',
        padding: "7px 14px",
       
      }}> <img src={biimg} alt="power bi img" style={{height:"13px",width:"22px"}} /> {intl.formatMessage({ id: 'OperationsReports' })}</span> 
    </button></> }
        <Grid
          item
          style={{
            flex: 1,
            justifyContent: 'flex-end',
            alignSelf: 'flex-end',
          }}
        >
          <Pagination />
        </Grid>
      </Grid>

      <EnhancedTableToolbar />

      <ActionAlert
        {...{
          isOpen: isActionConfirmationModalOpen,
          setOpen: setActionConfirmationModalOpen,
          setMultiActionTitle,
          setActionCall,
          action: actionCall,
          entityType: intl.formatMessage({ id: 'routes' }),
          actionName: multiActionTitle,
          onSuccess: () => {
            setSelectedItems([]);
            dispatch(fetchAction()(page, rowsPerPage));
          },
        }}
      />

      <DataTableContainer
        alignItems={routeListIsLoading ? 'center' : 'flex-start'}
      >
        {routeListHasError ? (
          <Typography align="center" variant="h5" color="textSecondary">
            {intl.formatMessage({ id: 'somethingWrong' })}
          </Typography>
        ) : (
          <>
            {routeListIsLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                {routeList.length ? (
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="Routes Table"
                  >
                    <TableHead>
                      {!selectedItems?.length && (
                        <TableRow>
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={!!isAllListSelected}
                              onClick={(event: React.MouseEvent) => {
                                event.stopPropagation();
                                toggleSelectAll();
                              }}
                            />
                          </TableCell>

                          <TableCell>
                            {intl.formatMessage({ id: 'englishName' })}
                          </TableCell>

                          <TableCell>
                            {intl.formatMessage({ id: 'arabicName' })}
                          </TableCell>

                          <TableCell>
                            {intl.formatMessage({ id: 'code' })}
                          </TableCell>

                          <TableCell>
                            {intl.formatMessage({ id: 'organization' })}
                          </TableCell>

                          <TableCell>
                            {intl.formatMessage({ id: 'status' })}
                          </TableCell>

                          <TableCell>
                            {intl.formatMessage({ id: 'groups' })}
                          </TableCell>

                          <TableCell align="center">
                            {intl.formatMessage({ id: 'actions' })}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableHead>

                    <TableBody>
                      {routeList.map(route => (
                        <RouteListItem
                          key={route.id}
                          {...{ route, toggleSelection }}
                          isSelected={selectedItems.includes(route.id)}
                        />
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <div style={{ margin: '0 auto' }}>
                    <SystemEmptyState
                      hasWriteAccess={hasRouteWriteAccess}
                      baseURL={baseURL}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </DataTableContainer>

      <Pagination />
    </div>
  );
};

export { RouteList };
