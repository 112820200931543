// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body,
body > div:first-of-type {
  height: 100%;
}

body {
  margin: 0;
  font-family: Manrope, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*******zoning Style*************/
@media only screen and (max-width: 900px) {
  .zoningSec{
    width:45% !important
  }
}
@media only screen and (max-width: 600px) {
.zoningSec{
  width:100% !important
}
}
.bi-btns:hover{
background-color: #F6F7FF !important;

border:0 !important
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;;;EAGE,YAAY;AACd;;AAEA;EACE,SAAS;EACT,gCAAgC;EAChC,mCAAmC;EACnC,kCAAkC;AACpC;AACA,iCAAiC;AACjC;EACE;IACE;EACF;AACF;AACA;AACA;EACE;AACF;AACA;AACA;AACA,oCAAoC;;AAEpC;AACA","sourcesContent":["html,\nbody,\nbody > div:first-of-type {\n  height: 100%;\n}\n\nbody {\n  margin: 0;\n  font-family: Manrope, sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n/*******zoning Style*************/\n@media only screen and (max-width: 900px) {\n  .zoningSec{\n    width:45% !important\n  }\n}\n@media only screen and (max-width: 600px) {\n.zoningSec{\n  width:100% !important\n}\n}\n.bi-btns:hover{\nbackground-color: #F6F7FF !important;\n\nborder:0 !important\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
