// @ts-nocheck
/* eslint-disable padding-line-between-statements, import/order, import/newline-after-import, spaced-comment */
/* eslint-disable @typescript-eslint/no-unused-vars, react/jsx-newline, react/jsx-no-bind, no-else-return */
/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps, @typescript-eslint/no-use-before-define, no-lonely-if, no-nested-ternary, import/no-extraneous-dependencies */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-newline */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import {
  createStyles,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import moment from 'moment';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { getTripSales } from 'actions';
import { CardTitle } from 'app/components/shared/card-title';
import { DataTableContainer } from 'app/components/shared/data-table-container';
import { LoadingSpinner } from 'app/components/shared/loading-spinner';
import { TextCell } from 'app/components/shared/table';
import { SystemEmptyState } from 'app/components/system-empty-state';
import { basicButtonStyles, tableRowStyles } from 'app/shared/styles';
import { LanguageContext } from 'context/intl.context';
import { splitValues, trimTrailingSlash, useTypedSelector } from 'helpers';
import { TripSale } from 'types';
import biimg from "./Power-BI-Logo.png";
import { toast } from 'react-toastify';
import { FilterTripSale } from './filter-trip-sale';
import { TripSalesFilterParams } from './filters';
import {
  PaginationComponent,
  usePagination,
} from '../../components/use-pagination';
const useStyles = makeStyles(() =>
  createStyles({
    table: { minWidth: 750, overflow: 'scroll', whiteSpace: 'nowrap' },
    addButton: { ...basicButtonStyles },
    row: { ...tableRowStyles },
  })
);

const TripSaleListItem = ({ tripSale }: { tripSale: TripSale }) => {
  const classes = useStyles();

  return (
    <TableRow className={classes.row}>
      <TextCell
        label={tripSale.axis_code}
        align="center"
        style={{ fontFamily: 'Cairo' }}
      />

      <TextCell
        label={tripSale.ticket_serial}
        align="center"
        style={{ fontFamily: 'Cairo' }}
      />

      <TextCell label={tripSale?.vehicle_code} />

      <TextCell
        label={`${tripSale.driver_username || ''} ${
          tripSale.driver_name || ''
        }`}
      />

      <TextCell
        label={moment(tripSale.created_at).format('DD/MM/YYYY HH:mm')}
        align="center"
      />

      <TextCell label={String(tripSale.price || '')} align="center" />
    </TableRow>
  );
};

const TripSaleList: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isSearching, setSearching] = useState(false);
  const [isFiltering, setFiltering] = useState(false);

  const [filterParams, setFilterParams] = useState<Record<string, string>>({});

  const { direction } = useContext(LanguageContext);

  const tripSaleListIsLoading = useTypedSelector(
    state => state.tripSales.isLoading as boolean
  );

  const tripSaleListHasError = useTypedSelector(
    state => state.tripSales.hasError
  );

  const tripSalesTotalCount = useTypedSelector(
    state => state.tripSales.totalCount
  );

  const tripSaleList = useTypedSelector(state => {
    if (!state.tripSales.isLoading && Object.values(state.tripSales).length) {
      return Object.values(state.tripSales.collection);
    }

    return [];
  });

  const fetchSearchResults = useCallback(
    (page: number, rowSize: number) =>
      getTripSales(page, rowSize, { q: { ticket_serial_cont: searchTerm } }),
    [searchTerm]
  );

  const fetchFilterResults = useCallback(
    (page: number, rowSize: number) => {
      const { driver, axis, vehicle, minDate, maxDate } = TripSalesFilterParams;

      return getTripSales(page, rowSize, {
        group_ids: filterParams.groups as unknown as string[],
        q: {
          [driver]: splitValues(filterParams[driver]),
          [axis]: splitValues(filterParams[axis]),
          [vehicle]: splitValues(filterParams[vehicle]),
          [minDate]: filterParams[minDate],
          [maxDate]: filterParams[maxDate],
        },
      });
    },
    [filterParams]
  );

  const fetchAction = useCallback(() => {
    if (isSearching) return fetchSearchResults;

    if (isFiltering) return fetchFilterResults;

    return getTripSales;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchFilterResults, isFiltering, isSearching]);

  const { rowsPerPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination({ fetchAction: fetchAction() });

  const resetPageState = () => {
    handleChangePage(null, 0);
  };

  const filterTripSales = (filters: Record<string, string>) => {
    if (Object.values(filters)?.length) {
      setFiltering(true);
      setSearching(false);
      setSearchTerm('');
    }

    resetPageState();

    setFilterParams(filters);
  };

  const handleSearch = () => {
    if (searchTerm) {
      dispatch(
        getTripSales(page, rowsPerPage, {
          q: { ticket_serial_cont: searchTerm },
        })
      );

      setSearching(true);
    } else {
      setSearching(false);
    }

    resetPageState();
  };

  const Pagination = () => (
    <PaginationComponent
      {...{
        totalCount: tripSalesTotalCount,
        hasData: !!tripSaleList.length,
        rowsPerPage,
        page,
        handleChangePage,
        handleChangeRowsPerPage,
      }}
    />
  );
  const openLink = (url) => {
    // Attempt to open a new window with as minimal UI as possible.
    // Note: Modern browsers may not allow hiding the address bar.
    window.open(url, '_blank', 'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=400');
  };

  const [adminOrgCode, setAdminOrgCode] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Loading state
       //auth
       let authData = null
       let userData = null
       let userToken =null
   
 try{
        
        authData =useSelector (state => state.authentication?.user);
         //authData = JSON.parse(localStorage.getItem("authData"));
         userData = authData.userData
         userToken = authData.userToken
 }catch(e){
     toast.error(`Sorry , ${"Auth Failed"} , Code : #85arx`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
      })   
 }
  useEffect(()=>{
//request to get org code using logged admin org id 
//by this code we using to build dynamic links to each org admin
    const getOrgCodeUsingAdminOrgId =  () => {
      setIsLoading(true); // Set loading to true when data is being fetched
    
      //setTimeout(() => {
    axios.get(`${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/fareMedia/getDashboardAdminOrgCodeUsingitsOrgId`,
    { 
      headers:
      {
      'access-token' : userToken['access-token'],
      'uid' :userToken.uid ,
      'client': userToken.client 
      }
      })
  .then((res)=> {
        setAdminOrgCode(res.data.data.org_code);
        console.log("admin org_code : ", res.data.data.org_code);
        setIsLoading(false); // Set loading to false when data is fetched
    })
    .catch((err)=>{
      toast.error(`Sorry , ${err} , Code : #85ar`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
        })           
    
        setIsLoading(false); // Set loading to false when data is fetched
         
      })
   // }, 3000);
  }
   getOrgCodeUsingAdminOrgId();

      },[])
     /* useEffect(()=>{
        console.log(JSON.stringify(tripSaleList));
      },[tripSaleList])*/
      // Function to remove duplicates based on ticket_serial

  return (
    <div dir={direction}>
      <CardTitle title={intl.formatMessage({ id: 'tripSales' })} />

      <Grid container style={{ justifyContent: 'space-between' }}>
        
      
   
        <Grid item>
          <FilterTripSale
            {...{
              searchTerm,
              setSearchTerm,
              handleSearch,
              showFilters: !!tripSaleList.length && !isSearching,
              filterTripSales,
              isSearching,
            }}
          />
        </Grid>
        {<>
        <button
      onClick={() => openLink(`https://bi.${adminOrgCode}.mwasla.tech`)}
      style={{
        backgroundColor: 'transparent',
        fontSize:"14px",
        border: 0,
        padding: 0,
        fontFamily: 'Arial, sans-serif',
        textDecoration: 'none',
        margin:"0px 20px"
      }}
    >
     <span   className='bi-btns' style={{

        color: '#4B506D',
        border: '1px solid #E6E6F1',
        borderRadius:"19px",
        cursor: 'pointer',
        padding: "7px 14px",
       
      }}> <img src={biimg} alt="power bi img" style={{height:"13px",width:"22px"}} />    {intl.formatMessage({ id: 'AFCReports' })} </span> 
    </button><button
      onClick={() => openLink(`https://bi.charge.${adminOrgCode}.mwasla.tech`)}
      style={{
        backgroundColor: 'transparent',
        fontSize:"14px",
        border: 0,
        padding: 0,
        fontFamily: 'Arial, sans-serif',
        textDecoration: 'none',
        margin:"0"
      }}
    >
     <span   className='bi-btns' style={{

        color: '#4B506D',
        border: '1px solid #E6E6F1',
        borderRadius:"19px",
        cursor: 'pointer',
        padding: "7px 14px",
       
      }}> <img src={biimg} alt="power bi img" style={{height:"13px",width:"22px"}} />   {intl.formatMessage({ id: 'CardsReports' })}</span> 
    </button></> }
        <Grid
          item
          style={{
            flex: 1,
            justifyContent: 'flex-end',
            alignSelf: 'flex-end',
          }}
        >
          <Pagination />
        </Grid>
      </Grid>

      <DataTableContainer
        alignItems={tripSaleListIsLoading ? 'center' : 'flex-start'}
      >
        {tripSaleListHasError ? (
          <Typography align="center" variant="h5" color="textSecondary">
            {intl.formatMessage({ id: 'somethingWrong' })}
          </Typography>
        ) : (
          <>
            {tripSaleListIsLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                {tripSaleList.length ? (
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="Trip Sale Table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          {intl.formatMessage({ id: 'axis' })}
                        </TableCell>

                        <TableCell align="center">
                          {intl.formatMessage({ id: 'code' })}
                        </TableCell>

                        <TableCell>
                          {intl.formatMessage({ id: 'vehicle' })}
                        </TableCell>

                        <TableCell>
                          {intl.formatMessage({ id: 'driver' })}
                        </TableCell>

                        <TableCell align="center">
                          {intl.formatMessage({ id: 'timeAndDate' })}
                        </TableCell>

                        <TableCell align="center">
                          {intl.formatMessage({ id: 'amount' })}
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {tripSaleList.map(tripSale => (
                        <TripSaleListItem key={tripSale.id} {...{ tripSale }} />
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <div style={{ margin: 'auto auto' }}>
                    <SystemEmptyState
                      hasWriteAccess={false}
                      baseURL={baseURL}
                      showNote={false}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </DataTableContainer>

      <Pagination />
    </div>
  );
};

export { TripSaleList };
